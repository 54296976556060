import type { Experiment } from "@/model";
import type { Fragment } from "@tiptap/pm/model";
import type { JSONContent } from "@tiptap/vue-3";
import { ref } from "vue";
import { XmlFragment } from "yjs";

/**
 * New method page can specify how the method should be initialized:
 * - from a user prompt
 *     When editor is created and detects that there is a user prompt it will initialize
 *     command-k with it and clear the ref.
 * - with auto numbering
 *     When editor is created and detects that there is auto numbering requested it
 *     will mark first step with auto numbering.
 * - from content
 *     For example when onboarding method is created after user is signed up
 */
export const initializeMethod = ref<
  | { type: "fromUserPrompt"; prompt: string }
  | { type: "withAutoNumbering" }
  | { type: "fromContent"; content: JSONContent }
  | { type: "compareWithMethod"; content: XmlFragment }
  | {
      type: "duplicateWithChanges";
      changes: string;
      originalExperiment: Experiment;
    }
  | null
>(null);
