/**
 * Contains functionality for communicating with the chrome plugin.
 */
export const AUTH_STATUS = {
  TYPE: "BRIEFLY_AUTH_STATUS",
  AUTHENTICATED: "authenticated",
  UNAUTHENTICATED: "unauthenticated",
};

export const isInIframe = () => {
  try {
    return window !== window.parent;
  } catch {
    return true;
  }
};

// Send status to parent (Chrome extension)
export const sendStatusToParent = (
  authStatus: string,
  userInfo?: { userId: string; email?: string; userToken?: string }
) => {
  if (!isInIframe()) return;

  const status = {
    type: AUTH_STATUS.TYPE,
    status: authStatus,
    url: window.location.href,
    userId: userInfo?.userId,
    email: userInfo?.email,
    userToken: userInfo?.userToken,
  };

  // ONLY send this to our extension
  window.parent.postMessage(
    status,
    "chrome-extension://gnnjcgmifoljjjmeefdhangmbnbhgihc"
  );
};
