/**
 * The naming of this plugin is deliberaly confusing in order to prevent
 * ad blockers from blocking it.
 */

import posthog from "posthog-js";
import { type App } from "vue";

export default {
  install(app: App) {
    app.config.globalProperties.$posthog = posthog.init(
      "phc_yBdKF0wFG5BGs7nZFpcmThewSmrwrJB60E8sSCjChG5",
      {
        api_host: "https://users.briefly.bio",
        session_recording: {
          maskAllInputs: false,
        },
        disable_session_recording: window.location.href.includes("localhost"),
      }
    );
    posthog.debug(false);
  },
};
