import { functions } from "@/firebase";
import { httpsCallable } from "firebase/functions";

interface DocId {
  workspaceId: string;
  id: string;
}

export const getYjsDocument = httpsCallable<
  { doc: DocId },
  {
    binaryDoc: Uint8Array;
    docTitle: string;
  }
>(functions, "getYjsDocument");

export const getVersionContent = async (workspaceId: string, id: string) => {
  const { data } = await getYjsDocument({
    doc: {
      workspaceId,
      id,
    },
  });
  return {
    binaryDoc: data.binaryDoc,
    docTitle: data.docTitle,
  };
};
